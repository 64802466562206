<template>
  <div class="academic">
    <nav class="navbar secondary-nav">
      <div class="container">
        <router-link class="secondary-nav__item" to="/students-resilience/personal/learn">LEARN</router-link>
        <router-link class="secondary-nav__item" to="/students-resilience/personal/discuss">DISCUSS</router-link>
        <router-link class="secondary-nav__item" to="/students-resilience/personal/share">SHARE</router-link>
        <router-link class="secondary-nav__item" to="/students-resilience/personal/design">DESIGN</router-link>
        <router-link class="secondary-nav__item" to="/students-resilience/personal/review">REVIEW</router-link>
      </div>
    </nav>

    <!-- ROUTER CONTENT SHOULD GO HERE -->
    <!-- <h1>{{$route.path}}</h1> -->
    <router-view/>
  </div>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },

  data () {
    return {
      // GET CANVAS WIDTH DEMO
      cW: 0,
      doRender: false
    }
  },

  mounted () {
    // // reset AvWaveform element width
    // this.cW = this.$refs.parentDiv.offsetWidth - (16 * 2)
    // // render it
    // this.doRender = true
  }
}
</script>
